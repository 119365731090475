<template>
    <div>
        <Navbar jenis="biasa"/>
        <main id="content" style="transform: none;margin-top: 10rem !important">
                <div class="content-widget" style="transform: none;">
                    <div class="container" style="transform: none;">
                        <div class="row" style="transform: none;">
                            <div class="col-md-8">
                                <h4 class="spanborder">
                                    <span>Search of : {{ search }}</span>          
                                </h4>
                                <article class="row justify-content-between mb-5 mr-0" v-for="item in data" :key="item.index">
                                    <div class="col-md-9 ">
                                        <div class="align-self-center">
                                            <div class="capsSubtle mb-2">Random Pick</div>
                                            <h3 class="entry-title mb-3"><router-link :to="`/${item.url}`">{{ item.title }}</router-link></h3>
                                            <div class="entry-excerpt">
                                                <p>
                                                   {{ item.description }}
                                                </p>
                                            </div>
                                            <div class="entry-meta align-items-center">
                                                <a :href="`/@${item.owner}/me`">{{ item.owner }}</a> in <a :href="`/@${item.owner}/me`">Random</a><br>                                    
                                                <span>{{ item.updated_date }}</span>
                                                <span class="middotDivider"></span>
                                                <span class="readingTime" title="3 min read">{{ item.reading.text }}</span>
                                                <span class="svgIcon svgIcon--star">
                                                    <svg class="svgIcon-use" width="15" height="15">
                                                        <path d="M7.438 2.324c.034-.099.09-.099.123 0l1.2 3.53a.29.29 0 0 0 .26.19h3.884c.11 0 .127.049.038.111L9.8 8.327a.271.271 0 0 0-.099.291l1.2 3.53c.034.1-.011.131-.098.069l-3.142-2.18a.303.303 0 0 0-.32 0l-3.145 2.182c-.087.06-.132.03-.099-.068l1.2-3.53a.271.271 0 0 0-.098-.292L2.056 6.146c-.087-.06-.071-.112.038-.112h3.884a.29.29 0 0 0 .26-.19l1.2-3.52z"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 bgcover">
                                        <img :src="item.thumbail_url" alt="">
                                    </div>
                                </article>
                            </div> <!--col-md-8-->
                        </div>
                    </div> <!--content-widget-->
                </div>
            </main>
    </div>
</template>
<script>
import axios from 'axios'
import $ from 'jquery'
import Navbar from '@/components/layouts/Navbar.vue'
import moment from 'moment'
import readingTime from 'reading-time'

export default {
    data() {
        return {
            data: null,
            search: ''
        }
    },
    mounted() {
        this.search = this.$route.query.q
        this.getData()
    },
    components: {
        Navbar,
    },
    methods: {
        async getData() {
            const data = await axios.get(`posting/search/${this.search}`)
            let arrayData = Array()
            data.data.forEach(element => {
                let toPush = null
                const startDesc = element.description.indexOf('<p class="blog_post_text text-left">')
                if (startDesc < 0) {
                    toPush = 'Nothing paragraph in here'  
                } else {
                    const jadi_string = $(element.description).text().trim().substr(0, 50)
                    toPush = jadi_string
                }
                const time = readingTime($(element.description).text().trim())
                const data = {
                    id: element.id,
                    title: element.title,
                    description: toPush,
                    thumbail_url: element.thumbail_url,
                    owner: element.ownerPosting.name,
                    url: element.url_perma,
                    owner_image: element.ownerPosting.profile_photo,
                    updated: (element.date_updated != "non") ? true : false,
                    updated_date: (element.date_updated != "non") ? moment(element.date_updated).format('MMMM D') : moment(element.date_created).format('MMMM D'),
                    reading: time
                }
                arrayData.push(data)
            });
            this.data = arrayData
        },

    }
}
</script>
<style scoped>

</style>